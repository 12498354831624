export default {
    name: "TermSheet",
    created() {
        this.$nextTick(function() {
            // 获取数据
            this.GetBursaAnnouncement()
            this.WarrantCodeFixation()
        });
    },
    mounted() {
        this.$nextTick(function() {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            dataArr: [],
            showDataArr: [],
            searchArr: [],
            showsearchArr: [],
            searchArrulF: false,
            inputText: '',
            inputTextlog: '',
            arrT: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取数据
        GetBursaAnnouncement() {
            let arr
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetBursaAnnouncement",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == 'SUCCESS') {
                        console.log(data);
                        $.each(data.text, (iv, cv) => {
                            cv.visible = false
                        })
                        this.searchArr = data.text
                        this.showsearchArr = this.searchArr
                        this.dataArr = data.text
                    }
                },
                error: function(XMLHttpRequest) {}
            });
        },
        // Warrant Code 固定
        WarrantCodeFixation: function() {
            $('#WarrantsAnnouncement').find('.table_box').scroll(function() {
                if ($(this).scrollTop() > 0) {
                    $('.CodeFixationTableBox').show()
                } else {
                    $('.CodeFixationTableBox').hide()
                }
                $('.CodeFixationTableBox').width($('#WarrantsAnnouncement').find('.table_box').outerWidth(true) - 5)
            })
        },
        searchArrFun: function(oi, e) {
            oi.visible = e.target.checked
            if (e.target.checked) {
                this.arrT[oi.id] = oi.name
                this.showDataArr[oi.id] = oi
            } else {
                this.arrT[oi.id] = ''
                this.showDataArr[oi.id] = ''
            }
            this.inputText = this.arrT.filter(tiem => tiem !== '')
            this.dataArr = this.showDataArr.filter(tiem => tiem !== '')
        },
        searchArrulop: function(num) {
            if (num !== 0) {
                this.searchArrulF = !this.searchArrulF
            } else {
                this.searchArrulF = false
            }
        },
        searchArrSeek: function(e) {
            console.log(e.target.value);
            this.inputText = e.target.value
            if (e.target.value.length == 0) {
                this.showsearchArr = this.searchArr
                this.searchArrulF = false
            } else {
                this.showsearchArr = this.searchArr.filter(function(tiem) {
                    return tiem.companyname.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
                })
                this.searchArrulF = true
            }
        },
        inputvanish: function() {
            this.inputTextlog = this.inputText
            this.inputText = ''
        },
        inputshow: function() {
            this.inputText = this.inputTextlog
        }
    }
};